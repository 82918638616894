import React, { useEffect, useState } from 'react';
import { Section, Hero } from '../components';
import ROI from '../assets/one.png';
import DualYield from '../assets/two.png';
import Decentralized from '../assets/three.png';
// import { PrimaryButton } from '../components/button';

import axios from 'axios';
import { apiUrl } from '../utils/constants';

const Landing: React.FC = () => {
  const [currentPrice, setCurrentPrice] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(apiUrl);
      setCurrentPrice(result.data.tickers[0].converted_last['usd']);
    };
    fetchData();
  }, []);

  return (
    <main className='flex flex-col'>
      <Hero feesEarned={currentPrice} />
      <Section image={Decentralized} title='Decentralized and Community Driven' paragraph=''>
        <p className='font-light sm:text-lg mt-5'>
          <b>
            The ELE smart contract is complete at launch. There was no ICO, no pre-sale, and no
            fundraising of any kind. There are no more features to add. There is no individual or
            team to be relied upon to give ELE any value.
          </b>
        </p>
        <p className='font-light sm:text-lg mt-5'>
          <b>
            If you are looking for a cryptocurrency with a centralized team or CEO that will make
            'partnerships', ELE is not the token for you.
          </b>
        </p>
      </Section>
      <Section
        image={DualYield}
        bgcolor='secondary'
        title='Multiple Income Streams'
        paragraph='ELE provides an automatic dual stream of income for holders.  Inflation pays you with each block and fees are distributed to you instantly with each transaction.'>
        <p className='font-light sm:text-lg mt-5'>
          Each Elevate TX has a 1% fee. .5% is instantly split between ELE holders and .5% is
          burned. Sit back and let your tokens earn you more.
        </p>
      </Section>
      <Section
        image={ROI}
        title='Inflation, Meet Deflation'
        paragraph='Elevate introduces a new transactionless inflation mechanism that combines with automatic fee distribution and aggressive token burning mechanics to create a diverse and sustainable yield bearing DeFi token.'>
        <p className='font-light sm:text-lg mt-5'>
          When volume is high the rate of burn will exceed the rate of inflation, reducing the token
          supply. When the volume is low the supply will increase faster than the burn. This dual
          function is a way to naturally encourage trading volume and keep inflation in check.
        </p>
      </Section>
    </main>
  );
};
export default Landing;
