import React from 'react';

interface IFooterLink {
  href: string;
  name: string;
}

interface IFooterItem {
  title: string;
  linkArr: IFooterLink[];
}

let socialLinks = [
  { href: 'https://twitter.com/ReflectFndtn', name: 'Twitter' },
  { href: 'https://t.me/elevate_community', name: 'Telegram' },
];

let elevateLinks = [
  { href: 'https://foundation-reflect.medium.com/', name: 'Medium' },
  { href: 'https://github.com/elevatedefi', name: 'Github' },
];

let exchangeLinks = [
  {
    href:
      'https://app.uniswap.org/#/swap?outputCurrency=0x48be867b240d2ffaff69e0746130f2c027d8d3d2',
    name: 'Uniswap',
  },
  // { href: '#', name: 'Coingecko' },
  {
    href: 'https://etherscan.io/token/0x48be867b240d2ffaff69e0746130f2c027d8d3d2',
    name: 'Etherscan',
  },
];

const Footer: React.FC = () => (
  <footer>
    <div className='container mx-auto flex flex-col'>
      <div className=' flex  justify-around py-4 text-sm sm:text-base'>
        <FooterItem title='Social' linkArr={socialLinks} />
        <FooterItem title='Elevate' linkArr={elevateLinks} />
        <FooterItem title='Links' linkArr={exchangeLinks} />
      </div>
    </div>
    <p className='text-sm font-bold bg-primary py-2 mt-8 text-center'>© 2021 elevatedefi.io</p>
  </footer>
);

const FooterItem: React.FC<IFooterItem> = ({ title, linkArr }) => {
  return (
    <div className='flex flex-col text-center space-y-2'>
      <h4>{title}</h4>
      {Array.isArray(linkArr) &&
        linkArr.map((link, key) => {
          return (
            <a key={key} href={link.href} rel='noopener noreferrer' target='_blank'>
              {link.name}
            </a>
          );
        })}
    </div>
  );
};

export { Footer };
