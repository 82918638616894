import React from 'react';
// import bg from '../assets/bg.png';
import { PrimaryButton, SecondaryButton } from '.';
import Lottie from './lottie';

interface IHero {
  feesEarned: string;
}

const Hero: React.FC<IHero> = ({ feesEarned }) => {
  return (
    <section
      // style={{backgroundImage: `url(${bg})`, backgroundSize: 'cover'}}
      className={`px-5 pb-10 md:py-20  bg-local text-center md:text-left object-fill bg-secondary text-white flex`}>
      <div className='container mx-auto flex'>
        <div className='flex-1'>
          <h1 className='text-2xl sm:text-4xl sm:text font-bold border-primary md:border-l-4 md:pl-4 mb-4'>
            Effortless Passive Income.
          </h1>
          <p className='sm:text-lg font-light'>
            ELE is a DeFi 2.0 token that pays you just for holding it.
          </p>
          <br />
          <p className='sm:text-lg font-light'>
            You dont need to interact with confusing interfaces or pay expensive gas fees. As soon
            as you hold ELE in any non-custodial Ethereum wallet you will instantly start earning.
          </p>
          <div className='mt-10'>
            <div className='hero-buttons'>
              <a href='https://t.me/elevate_community' target='_blank' rel='noopener noreferrer'>
                <SecondaryButton text='Telegram' />
              </a>
            </div>
            <div className='hero-buttons'>
              <a
                href='https://app.uniswap.org/#/swap?outputCurrency=0x48be867b240d2ffaff69e0746130f2c027d8d3d2'
                target='_blank'
                rel='noopener noreferrer'>
                <PrimaryButton text='Buy ELE (Uniswap)' />
              </a>
            </div>
            {/* <div className='hero-buttons'>
              <a
                href='https://www.dharma.io/token/0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7'
                target='_blank'
                rel='noopener noreferrer'>
                <PrimaryButton text='Buy RFI (Dharma)' />
              </a>
            </div> */}
          </div>
        </div>
        <div
          className='hidden lg:flex flex-1 items-center justify-end'
          style={{ marginRight: '60px' }}>
          <Lottie />
        </div>
      </div>
    </section>
  );
};
export { Hero };
