import React, { useState } from 'react';
import { CgMenu, CgClose } from 'react-icons/cg';
import LogoLight from '../assets/nav_logo.png';

const Header: React.FC = () => {
  const [toggle, setToggle] = useState(false);
  const mobileNav = toggle ? 'block' : 'hidden';
  return (
    <header className='bg-secondary'>
      <nav className='container mx-auto md:flex items-center justify-between flex-wrap px-5 sm:px-0 py-5 text-white'>
        <div className='flex text-xl md:text-xl justify-between'>
          <span className='flex items-center'>
            <img src={LogoLight} style={{ maxHeight: '100px' }} alt='ELE logo' />
          </span>
          <button
            className='md:hidden text-2xl disable-zoom-tap'
            onClick={() => setToggle(!toggle)}>
            {!toggle ? <CgMenu /> : <CgClose />}
          </button>
        </div>
        <div
          className={`${mobileNav} mt-5 md:mt-0 md:flex space-y-5 md:space-y-0  md:space-x-10 flex flex-col md:flex-row items-center text-center`}>
          <a
            href='https://foundation-reflect.medium.com/'
            target='_BLANK'
            rel='noopener noreferrer'>
            Medium
          </a>
          <a href='https://github.com/elevatedefi' target='_BLANK' rel='noopener noreferrer'>
            Github
          </a>
          {/* <a href='https://github.com/reflectfinance' target='_BLANK' rel='noopener noreferrer'>
            Etherscan
          </a> */}
          {/* <a href='https://app.reflect.finance/farm' target='_BLANK' rel='noopener noreferrer'>
            Yield Farming
          </a> */}
        </div>
      </nav>
    </header>
  );
};
export { Header };
